<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="12" md="5">
        <app-text-search-field
          @filter="showFilter = !showFilter"
          @input="handleSearchInput($event)"
        />
      </v-col>
      <v-col class="text-right" cols="12" md="7">
        <v-btn
          class="mr-2"
          bottom
          large
          color="primary"
          v-if="$acl.can('productCreateEdit')"
          @click="openStoreProductForm()"
        >
          Novo
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              bottom
              large
              outlined
              color="secondary"
              v-if="$acl.can('productCreateEdit')"
              v-bind="attrs"
              v-on="on"
            >
              Opções
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="openExportProductForm()">
              <v-list-item-title> Exportar </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="$router.push({ name: 'ProductMassUpdateIndex' })"
            >
              <v-list-item-title> Atualização em massa </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center" v-if="showFilter">
      <v-col cols="12" md="3">
        <ClassificacaoFiscalSelect
          @input="select()"
          v-model="searchParams.classificacao_fiscal"
        />
      </v-col>
      <v-col cols="12" md="3">
        <CategoriesSearch
          @input="handleRelationshipInput($event, 'category_id')"
        />
      </v-col>
      <v-col cols="12" md="3">
        <BrandsSearch @input="handleRelationshipInput($event, 'brand_id')" />
      </v-col>
      <v-col cols="12" md="3">
        <SupplierSearch
          @input="handleRelationshipInput($event, 'supplier_id')"
        />
      </v-col>
      <v-col>
        <v-text-field
          @input="select()"
          v-model="searchParams.purchase_id"
          label="Id Compra"
        />
      </v-col>

      <v-col cols="12" md="3">
        <app-number-field
          v-model="searchParams.available_stock_more_than"
          :allowNegative="true"
          :forceZero="false"
          label="Estoque maior que"
          @change="select()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <app-number-field
          v-model="searchParams.available_stock_less_than"
          :allowNegative="true"
          :forceZero="false"
          label="Estoque menor que"
          @change="select()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-switch
          v-model="searchParams.low_stock"
          label="Abaixo do estoque mínimo"
          @change="select()"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-chip-group
          @change="select()"
          v-model="searchParams.status"
          column
          multiple
        >
          <v-chip filter :value="1" color="primary">Ativo</v-chip>
          <v-chip filter :value="0">Inativo</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          ref="productTable"
          @click:row="openStoreProductForm($event.id)"
          :headers="headers"
          :items="products.data"
          :mobile-breakpoint="0"
          :items-per-page="products.per_page"
          :loading="loading"
          hide-default-footer
          :item-class="checkIfRowIsUpdated"
          show-expand
        >
          <template v-slot:[`top`]>
            <div class="d-flex justify-space-between">
              <div>
                <v-switch
                  v-model="expanded"
                  small
                  hide-details
                  label="Exibir estoque"
                />
              </div>
              <div class="text-right">
                <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="text-capitalize"
                      color="primary"
                      text
                      dark
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      Editar Colunas
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group>
                      <template v-for="(item, index) in allHeaders">
                        <v-list-item
                          :key="index"
                          :value="item"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.text"
                            ></v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-checkbox
                              v-model="headers"
                              :value="item"
                            ></v-checkbox>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
            <small class="text--secondary">
              #{{ item.code }} | w{{ item.product_variants[0].webcom_id }}
            </small>
          </template>
          <template v-slot:[`item.price_cash`]="{ item }">
            <div @click.stop="handleUpdateCost(item)">
              {{ $format.decimal(item.product_variants[0].price_cash) }}
            </div>
          </template>
          <template v-slot:[`item.price_forward`]="{ item }">
            {{ $format.decimal(item.product_variants[0].price_forward) }}
          </template>
          <template v-slot:[`item.profit`]="{ item }">
            {{ $format.decimal(item.product_variants[0].profit) }}%
          </template>
          <template v-slot:[`item.cost_updated_at`]="{ item }">
            {{ $format.dateBr(item.product_variants[0].cost_updated_at) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
          <template v-slot:expanded-item="{ item }">
            <td></td>
            <td colspan="99">
              <v-simple-table class="expanded-table" dense>
                <template v-slot:default>
                  <tbody>
                    <template
                      v-for="(product_company, index) in item
                        .product_variants[0].product_companies"
                    >
                      <tr :key="index" v-if="showStockLine(product_company)">
                        <td style="width: 10%">
                          <small>
                            {{ product_company.company.short_name }}
                          </small>
                        </td>
                        <td style="width: 10%">
                          <ProductStockLabel
                            :productCompany="product_company"
                          />
                        </td>
                        <td style="width: 10%">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <small v-bind="attrs" v-on="on">
                                <v-icon small left>
                                  mdi-package-variant-closed-remove
                                </v-icon>
                                {{$format.decimal(product_company.reserved_stock)}}
                              </small>
                            </template>
                            <span>Reservado</span>
                          </v-tooltip>
                        </td>
                        <td style="width: 10%">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <small v-bind="attrs" v-on="on">
                                <v-icon small left> mdi-dolly </v-icon>
                                {{$format.decimal(product_company.expedition_stock)}}
                              </small>
                            </template>
                            <span>Expedição</span>
                          </v-tooltip>
                        </td>
                        <td style="width: 10%">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <small v-bind="attrs" v-on="on">
                                <v-icon small left>mdi-view-grid-outline</v-icon>
                                {{$format.decimal(product_company.physic_stock)}}
                              </small>
                            </template>
                            <span>Físico</span>
                          </v-tooltip>                          
                        </td>
                        <td style="width: 10%">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <small v-bind="attrs" v-on="on">
                                <v-icon small left>mdi-truck-fast-outline</v-icon>
                                {{$format.decimal(product_company.transfer_stock)}}
                              </small>
                            </template>
                            <span>Em Transferência</span>
                          </v-tooltip>                              
                        </td>
                        <td></td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
        <app-pagination :data="products" @click="select($event)" />
      </v-col>
    </v-row>
    <StoreProductForm
      @store="handleUpdatedProduct($event)"
      ref="StoreProductForm"
    />
    <UpdatePriceForm
      @store="handleUpdatedProduct($event)"
      ref="UpdatePriceForm"
    />
    <ExportProductForm ref="ExportProductForm" />
  </div>
</template>

<script>
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import ClassificacaoFiscalSelect from "@/components/product/ui/ClassificacaoFiscalSelect";
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CategoriesSearch from "@/components/categories/ui/CategoriesSearch.vue";
import StoreProductForm from "@/components/product/forms/StoreProductForm.vue";
import UpdatePriceForm from "@/components/product/forms/UpdatePriceForm.vue";
import ProductStockLabel from "@/components/product/ui/ProductStockLabel.vue";
import ExportProductForm from "@/components/product/forms/ExportProductForm.vue";

export default {
  components: {
    ClassificacaoFiscalSelect,
    BrandsSearch,
    SupplierSearch,
    CategoriesSearch,
    StoreProductForm,
    UpdatePriceForm,
    CompanyChip,
    ProductStockLabel,
    ExportProductForm,
  },

  data() {
    return {
      headers: [
        {
          text: "Descrição",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Preço Av",
          value: "price_cash",
          sortable: false,
          align: "right",
          width: "10%",
        },
        {
          text: "Preço Pz",
          value: "price_forward",
          sortable: false,
          align: "right",
          width: "10%",
        },
        { text: "Status", value: "status", align: "center", width: "10%" },
      ],

      allHeaders: [
        {
          text: "Descrição",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "Preço Av",
          value: "price_cash",
          sortable: false,
          align: "right",
          width: "10%",
        },
        {
          text: "Preço Pz",
          value: "price_forward",
          sortable: false,
          align: "right",
          width: "10%",
        },
        {
          text: "Lucro",
          value: "profit",
          sortable: false,
          align: "right",
        },
        {
          text: "Alteração de custo",
          value: "cost_updated_at",
          sortable: false,
          align: "right",
          width: "10%",
        },
        { text: "Status", value: "status", align: "center", width: "10%" },
      ],

      showFilter: null,

      products: {
        data: [],
      },

      expanded: true,

      loading: false,

      updated: [],

      searchParams: {
        status: [1],
        text: null,
        classificacao_fiscal: "00",
        category_id: null,
        supplier_id: null,
        brand_id: null,
        available_stock_more_than: null,
        available_stock_less_than: null,
        low_stock: null,
        //purchase_id: null,
        page: 1,
      },
    };
  },

  created() {
    this.select();
  },

  watch: {
    expanded(value) {
      this.toggleExpand(value);
    },
  },

  methods: {
    checkIfRowIsUpdated(item) {
      if (this.updated.some((row) => item.id == row.id)) {
        return "updatedRow";
      }
      return null;
    },

    handleUpdatedProduct(product) {
      this.updated.push(product);
      this.select(this.searchParams.page);
    },

    handleRelationshipInput(relationship, field) {
      if (relationship) {
        this.searchParams[field] = relationship.id;
      } else {
        this.searchParams[field] = null;
      }

      this.select();
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleUpdateCost(item) {
      if (this.$acl.can("productUpdatePrice")) {
        this.$refs.UpdatePriceForm.open(item.id);
      }
    },

    openStoreProductForm(id = null) {
      if (this.$acl.can("productCreateEdit")) {
        this.$refs.StoreProductForm.open(id);
      }
    },

    async select(page = 1) {
      this.searchParams.page = page;

      this.loading = true;

      await this.$http
        .index("product/product", this.searchParams)
        .then((response) => {
          this.products = response.products;
          this.toggleExpand(this.expanded);
        })
        .catch((error) => {});

      this.loading = false;
    },

    toggleExpand(value) {
      for (let product of this.products.data) {
        this.$set(this.$refs.productTable.expansion, product.id, value);
      }
    },

    showStockLine(productCompany) {
      if (
        productCompany.available_stock != 0 ||
        productCompany.reserved_stock != 0 ||
        productCompany.expedition_stock != 0 ||
        productCompany.physic_stock != 0 ||
        productCompany.transfer_stock != 0
      ) {
        return true;
      }

      if (productCompany.can_sell == 1) {
        return true;
      }

      return false;
    },
    openExportProductForm() {
      this.$refs.ExportProductForm.open();
    },
  },
};
</script>

<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f5f5f5;
}

.expanded-table {
  background-color: #f5f5f5 !important;
}

.expanded-table td {
  border-bottom: red !important;
}

.updatedRow {
  color: green !important;
}
</style>
