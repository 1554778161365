<template>
  <ProductCostsFormDrawer
    ref="ProductCostsFormDrawer"
    :value="form.product_variants[0]"
    @input="handleSave($event)"
  />
</template>

<script>
import ProductCostsFormDrawer from "@/components/product/forms/ProductCostsFormDrawer.vue";

export default {
  components: {
    ProductCostsFormDrawer,
  },

  data: () => ({
    form: {
      status: 1,
      product_variants : [{}]
    },
  }),

  methods: {
    async open(id) {

      await this.showProduct(id);

      this.$refs.ProductCostsFormDrawer.open();
    },

    async showProduct(id) {
      if (id) {
        this.$loading.start();
        await this.$http
          .$get(`product/product/${id}`)
          .then((response) => {
            this.form = response.product;
            this.$loading.finish();
          })
          .catch((error) => {
            this.$loading.finish();
            this.dialog = false;
          });
      }
    },

    handleSave(data) {
      this.form.product_variants[0] = data

      this.$loading.start();
      this.$http
        .update("product/product", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.$emit("store", this.form);
      this.$loading.finish();
      this.dialog = false;
    },
  },

};
</script>

<style>
</style>

